import React, { useEffect, useRef } from "react"
import debounce from "lodash.debounce"
import Img from "gatsby-image"
import Player from "@vimeo/player"

// Utils
import { maintainAspectRatio } from "../../utils/video"

// Styles
import styles from "./video.module.scss"

const VideoComponent = props => {
  const { videoProps } = props,
    { fluid } = props,
    { alt } = props,
    { controls } = props,
    { home } = props

  const videoString = controls
    ? `autoplay=0&controls=1`
    : `autoplay=1&loop=1&muted=1&title=0&sidedock=0&controls=0&autopause=0`

  const videoRef = useRef(),
    videoIframe = useRef(),
    videoAspectRatio = useRef()

  useEffect(() => {
    const { current } = videoRef.current
    const resizeVideo = debounce(maintainAspectRatio, 500)
    const player = new Player(videoIframe.current)

    player.ready().then(() => {
      setTimeout(() => {
        if (player && !controls) {
          if (player.play) {
            player.play()
            if (current) {
              current.classList.add("hidden")
            }
          }
        }
      }, 2000)
    })

    !controls &&
      Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
        function (dimensions) {
          var width = dimensions[0]
          var height = dimensions[1]

          videoAspectRatio.current = width / height

          resizeVideo(videoIframe.current, videoAspectRatio.current)

          window.addEventListener(
            "resize",
            resizeVideo.bind(
              null,
              videoIframe.current,
              videoAspectRatio.current
            ),
            false
          )
        }
      )

    return () => {
      !controls &&
        window.removeEventListener("resize", debounce(maintainAspectRatio, 500))
    }
  }, [controls])

  return (
    <div
      ref={videoRef}
      className={home === true ? styles.videoWrapHome : `${styles.videoWrap}`}
    >
      {fluid && <Img fluid={fluid} alt={alt} />}
      <div className="embed-container">
        <iframe
          src={`https://player.vimeo.com/video/${videoProps}?${videoString}`}
          height="315"
          width="560"
          className={`video ${styles.video}`}
          id={`heroVideo`}
          frameBorder="0"
          title={`Hero Video`}
          ref={videoIframe}
        ></iframe>
      </div>
    </div>
  )
}

export default VideoComponent
