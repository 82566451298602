export const maintainAspectRatio = (el, aspectRatio) => {
  if (el) {
    if (el.parentNode) {
      const parentWidth = el.parentNode.offsetWidth,
        parentHeight = el.parentNode.offsetHeight

      if (parentWidth > parentHeight * aspectRatio) {
        el.style.height = parentWidth / aspectRatio + "px"
        el.style.width = parentWidth + "px"
        el.style.marginLeft = "0"
        el.style.marginTop =
          (parentHeight - parentWidth / aspectRatio) / 2 + "px"
      } else {
        el.style.height = parentHeight + "px"
        el.style.width = parentHeight * aspectRatio + "px"
        el.style.marginLeft =
          (parentWidth - parentHeight * aspectRatio) / 2 + "px"
        el.style.marginTop = "0"
      }
    }
  }
}
